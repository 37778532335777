@marginBottom: 12px;
@marginBottomMiddle: 8px;
@marginBottomSmall: 6px;

.tx-table-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;

  .tx-table-tree-wrapper {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-right: 12px;
    box-sizing: border-box;

    .tx-table-search-wrapper {
      .ant-input-affix-wrapper {
        border-radius: 0;
      }
    }

    .tx-table-tree {
      flex: 1;
      border: 1px solid #d9d9d9;
      border-top: 0;
      overflow: auto;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;

          .tx-table-tree-option {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            align-items: center;
          }

          .tx-table-tree-content {
            overflow: auto;
            white-space: nowrap;
            flex-grow: 1;
            padding-top: 4px;
          }
        }
      }
    }
  }

  .tx-table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 0;

    .tx-table-header {
      .searchWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        box-sizing: border-box;

        .ant-form-item {
          margin-bottom: @marginBottom;
        }
      }

      .buttonGroup {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-bottom: @marginBottom;

        .ant-btn {
          margin-right: 0.08rem;
        }
      }

      .extraButtonGroup {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-bottom: @marginBottom;

        .ant-btn {
          margin-right: 0.08rem;
        }
      }
    }

    .tx-table {
      height: 0;
      flex-grow: 1;

      .ant-spin-nested-loading {
        height: 100%;
        padding-bottom: 57px;
        box-sizing: border-box;

        .ant-spin-container {
          height: 100%;

          .ant-table {
            height: 100%;

            .ant-table-container {
              height: 100%;
              display: flex;
              flex-direction: column;

              .ant-table-content {
                height: 100%;
              }

              .ant-table-body {
                flex: 1;
                max-height: 100% !important;
              }
            }
          }
        }
      }
    }

    &.table-noData {
      table {
        height: 100%;
        thead {
          tr {
            height: 46px;
          }
        }
      }
    }

    &.table-noPage {
      .ant-spin-nested-loading {
        padding-bottom: 0;
      }
    }

    .ant-table-pagination {
      .ant-pagination-total-text {
        float: right;
        margin-left: 6px;
      }
    }
  }
}

.tx-column-setting {
  .ant-popover-inner-content {
    padding: 0;

    .tx-column-setting-content {
      padding: 0.12rem 0.16rem;
      max-height: 400px;
      overflow: auto;
    }
  }
  .tx-column-setting-footer {
    text-align: right;
    padding: 0.04rem 0.12rem;
    border-top: 1px solid #f0f0f0;
    line-height: 0.34rem;
  }
}

.tx-table-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  .tx-table-wrapper {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 12px 8px;
    }

    .tx-table-header {
      .searchWrapper {
        .ant-form-item {
          margin-bottom: @marginBottomMiddle;
        }
      }

      .buttonGroup {
        margin-bottom: @marginBottomMiddle;
      }

      .extraButtonGroup {
        margin-bottom: @marginBottomMiddle;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .tx-table-wrapper {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 12px 8px;
    }

    .tx-table-header {
      .searchWrapper {
        .ant-form-item {
          margin-bottom: @marginBottomSmall;
        }
      }

      .buttonGroup {
        margin-bottom: @marginBottomSmall;
      }

      .extraButtonGroup {
        margin-bottom: @marginBottomSmall;
      }
    }
  }
}
