.ol-tx-infoWindow-overlay {
    z-index: 600;
    position: relative;
    width: 0;
    height: 0;
    cursor: default;

    .ol-tx-infoWindow {
        position: absolute;
        bottom: 0;
        left: 50%;

        .ol-tx-infoWindow-body {
            box-shadow: 0 0px 10px rgba(0, 0, 0, .3);
            background-color: #fff;
            border-radius: 2px;
            box-sizing: b order-box;
            position: relative;
        }

        .ol-tx-infoWindow-triangle {
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #fff;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 100%);

        }
    }
}