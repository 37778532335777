.tx-infowindow {
  width: 515px;
  background-color: #FFF;
  position: relative;

  .infowindow-header {
    height: 40px;
    padding: 8px 14px;
    display: flex;
    background-color: #F2F5F7;
    justify-content: space-between;
    align-items: center;

    >div {
      font-size: 14px;
      font-weight: bold;
    }

    .header-title {
      >span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }

  .infowindow-content {
    padding: 2px 18px;

    .tx-info-item {
      padding: 2px 0;
    }
  }

  .infowindow-footer {
    display: flex;
    border-top: 1px solid #eee;

    >div {
      display: flex;
      flex-grow: 1;
      width: 0;
      height: 36px;
    }

    .video,
    .trajectory,
    .playback,
    .photograph,
    .call,
    .search {
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &:hover {
        background-color: #e6f7ff;
      }
    }

    .video {
      background-image: url(/images/monitor/infowindow-1.png);

      &:active {
        background-image: url(/images/monitor/infowindow-1-1.png);
        background-color: #307CEC;
      }
    }

    .playback {
      background-image: url(/images/monitor/infowindow-2.png);

      &:active {
        background-image: url(/images/monitor/infowindow-2-1.png);
        background-color: #307CEC;
      }
    }

    .trajectory {
      background-image: url(/images/monitor/infowindow-3.png);

      &:active {
        background-image: url(/images/monitor/infowindow-3-1.png);
        background-color: #307CEC;
      }
    }

    .photograph {
      background-image: url(/images/monitor/infowindow-4.png);

      &:active {
        background-image: url(/images/monitor/infowindow-4-1.png);
        background-color: #307CEC;
      }
    }

    .call {
      background-image: url(/images/monitor/infowindow-5.png);

      &:active {
        background-image: url(/images/monitor/infowindow-5-1.png);
        background-color: #307CEC;
      }
    }

    .search {
      background-image: url(/images/monitor/infowindow-6.png);

      &:active {
        background-image: url(/images/monitor/infowindow-6-1.png);
        background-color: #307CEC;
      }
    }

  }
}