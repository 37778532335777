.ol-tx-marker {
    position: relative;
    width: 0;
    height: 0;
    cursor: default;
    
    .ol-tx-marker-icon {
        position: absolute;
        transform: translate(-100%, -50%);
        z-index: 1;
        cursor: pointer;
    }

    .ol-tx-marker-label {
        white-space: nowrap;
        position: absolute;
        z-index: 2;
        transform: translate(-40%, 50%);
    }
}