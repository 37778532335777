@fontColor: #434343;
@sliderBar: #fafafa;
@sliderBarBorder: #d9d9d9;
@buttonBorder: #bfbfbf;
@selectedColor: #1890ff;
@selectedBackgroundColor: #bae7ff;
@successColor: #36cfc9;
@successBackgroundColor: #b5f5ec;
@errorColor: #ff4d4f;
@errorBackgroundColor: #ffccc7;

.image-code {
  user-select: none;

  .image-container {
    position: relative;
    background-color: #ddd;
    margin-bottom: 0.2rem;

    .canvas {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .slider-wrpper {
    position: relative;
    margin: 0.1rem 0;

    .slider-bar {
      height: 0.5rem;
      line-height: 0.5rem;
      font-size: 0.14rem;
      text-align: center;
      color: @fontColor;
      background-color: @sliderBar;
      box-sizing: border-box;
      border: 1px solid @sliderBarBorder;
      // transition: background-color 0.3s, border-color 0.3s;
      transition: all 0.3s;

      span {
        transition: all 0.3s;
      }

      &.slider-bar-selected {
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: @selectedBackgroundColor;
        border-color: @selectedColor;
        transition: background-color 0.3s, border-color 0.3s;
      }

      &.slider-bar-success {
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: @successBackgroundColor;
        border-color: @successColor;
      }

      &.slider-bar-error {
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: @errorBackgroundColor;
        border-color: @errorColor;
      }
    }

    .slider-button {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      cursor: pointer;
      border: 1px solid @buttonBorder;
      border-radius: 0.02rem;
      box-sizing: border-box;
      font-size: 0.18rem;
      transition: all 0.3s;

      &:hover {
        color: white;
        background-color: @selectedColor;
        border-color: @selectedColor;
      }

      &.slider-button-selected {
        transition: color 0.3s, background-color 0.3s, border-color 0.3s;
      }

      &.slider-button-success {
        color: white;
        transition: all 0.3s;
        background: @successColor;
        border-color: @successColor;

        &:hover {
          background-color: @successColor;
          border-color: @successColor;
        }
      }

      &.slider-button-error {
        color: white;
        transition: all 0.3s;
        background: @errorColor;
        border-color: @errorColor;
        transition: all 0.3s;

        &:hover {
          background-color: @errorColor;
          border-color: @errorColor;
        }
      }
    }
  }
}
