@tableHeaderColor: #f2f5f7; // table中header的颜色
@tableOddRowColor: #fff; // table奇数行颜色
@tableEvenRowColor: #f6f7fa; // table偶数行颜色
@tableBoederColor: #eee; // table边框颜色
@tableSelectedRowColor: #d9e3f6; // 选中行的颜色

@scrollbarColor: #c8c8c8; // 滚动条的颜色
@scrollbarHoverColor: #767676; // 滚动条选中时的颜色
@scrollbarWidth: 17px; // 滚动条的宽度
@scrollbarRadius: @scrollbarWidth / 2; // 滚动条圆角
@scrollbarBorderColor: #eaeaea; // 滚动条边框颜色

.tx-table {
  .ant-table-thead {
    tr {
      th {
        background-color: @tableHeaderColor;
        border-bottom: none;
      }

      .tx-table-serial-number-col {
        padding: 0;
        text-align: center;
      }
    }
  }

  &.tx-table-base-no-bordered {
    .ant-table-tbody {
      tr:not(:last-of-type) {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      .tx-table-serial-number-col {
        padding: 0;
        text-align: center;
      }
    }

    .ant-table-row:hover {
      td {
        background-color: @tableSelectedRowColor;
      }
    }

    .ant-table-row.ant-table-row-selected {
      td {
        background-color: @tableSelectedRowColor;
      }
    }

    tr.ant-table-odd-row {
      td {
        background-color: @tableOddRowColor;
      }
    }

    tr.ant-table-even-row {
      td {
        background-color: @tableEvenRowColor;
      }
    }
  }
  .ant-spin-nested-loading {
    .ant-spin-container {
      overflow: unset;
    }
  }
}
